export default {
  "general": {
    "profile": {
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to download the profile"])}
    },
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
    "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa"])},
    "home": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa - Home"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUMP is the best advertising agency, and production company in KSA, Riyadh, Jeddah, Eastern Province, Dammam We provide creative services such as script writing, Video production, graphic design, storyboard drawing, animation production, motion graphics, branding and identity production, logo design, photography, drone photography, drone filming"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump company,Jump advertising, Creative agency,Video Production,Production Company,Production House,Advertising Agency,Motion Graphics production,Marketing Agency,Ad company,Ad agency riyadh,Branding company,Logo design company,Advertising company,Video Equipment rental,Filming company,Advertising production,Voice over production,VO production,"])}
    },
    "video": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production - jump sa "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional video production, videographer, scriptwriting, storyboard drawing, actors casting, cast, models,, video editing, photography, Chroma filming, Green screen filming, Green screen location rental, filming in Riyadh"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video production, Production house, Ad production, Advertising production, Video montage, Filming company, Professional video, Casting, Models, Actors, Chroma filming, Green screen filming, Green screen video,  "])}
    },
    "motion": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics - jump sa "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion graphics production, animation production, storyboard drawing, animation, voice over recording, music and sound effects"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion graphics, Animation production, Character Animation, Motiongraphics, Motion Graphics Production, Script writing, Voice over, music and sound effects, "])}
    },
    "branding": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding - jump sa "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design your brand identity, design logo, branding booklet, brand guidelines, design office stationary, design company profile"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding, Branding agency, Brand and Identity design, Logo design, Identity design, Profile design, Presentation design, brand guidelines,  "])}
    }
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production"])},
    "motion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics"])},
    "behind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behind Scenes"])},
    "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
    "actors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actors"])},
      "actor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an actor"])}
    },
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent Registration"])},
    "cast": {
      "ActorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actor Form"])},
      "DirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director Form"])},
      "VgrapherForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videographer Form"])},
      "Photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographer Form"])},
      "editorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor Form"])},
      "stylistForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wardrobe Stylist Form"])},
      "aDirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Director Form"])},
      "voiceForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice Over Talent Form"])},
      "modelForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Form"])}
    },
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي"])}
  },
  "section": {
    "Video Production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production"])},
    "Motion Graphics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics"])},
    "Behind the Scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behind the Scenes"])},
    "Branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
    "Bvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behind the Scenes"])},
    "chooseActors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an Actor"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='text-red-500'>JUMP</span> is a pioneering Saudi Advertising and production company with a wide client base in the Gulf and the Middle east region.<br>       We offer a diverse range of services, like creative copywriting, brainstorming, storyboarding, animation/motion graphics, video production, and branding."])}
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you want to produce a TV or web commercial, a documentary about your company, a motion-graphic video, or your unique Identity and branding, you are in the right place! We create all the above to highest quality which will make your content unforgettable. "])}
  },
  "video": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production Stages"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/video.png"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When it comes to video production all you have to do is explain your objectives to us and we will transform your vision into compelling content and videos that can easily reach the hearts of your audience. Find below some samples of our work, thoroughly produced by Jump from idea to final cut."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scriptwriting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storyboarding"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Scouting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shooting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-over Recording"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing and Sound Design"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphics / VFX"])}
      }
    ]
  },
  "motion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics Production Stages"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/motion.png"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation or motion graphics is a marvellous and cost effective way to increase your brand awareness, introduce your products, or transform your website visitors into clients.. Entice them in a creative way! Find below some samples of our work, thoroughly produced by Jump from idea to final cut."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scriptwriting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storyboarding"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-over Recording"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding Music and Effects"])}
      }
    ]
  },
  "branding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding Stages"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/branding.png"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your logo or visual identity is the first thing that your audience will notice even before they get to know about your services. Creating a creative identity that is 100% in sync with your business persona is a sure way to attract your target audience. Find below samples of our branding work."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client takes a survey about his/her business or product"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create a number of logo options"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating identity Guide"])}
      }
    ]
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are always ready to help you. Feel free to contact us for any questions or inquiries you may have."])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po.box 826 Riyadh 11421 KSA"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+966 540 233 695"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
      "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "subjectPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message subject"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been sent successfully."])}
    }
  },
  "casting": {
    "actorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل ممثل"])},
    "directorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل مخرج"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "slider": {
      "ActorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ActorForm"])},
      "DirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DirectorForm"])},
      "VgrapherForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VgrapherForm"])},
      "Photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographer"])},
      "editorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editorForm"])},
      "stylistForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stylistForm"])},
      "aDirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aDirectorForm"])},
      "voiceForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voiceForm"])},
      "modelForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelForm"])}
    },
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجال دراستك"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدى اجادتك للغة الانجليزية"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accent"])},
      "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي أنواع كاميرات تستخدم"])},
      "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي معدات التصوير التي تملكها"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك الخبرة في التصوير بصيغة LOG"])},
      "experience2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع علامة بجانب الأشياء التي تجيد تصويرها"])},
      "experienceEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي برنامج مونتاج تستخدم؟"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "vimeo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابك على Vimeo"])},
      "insta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram Account"])},
      "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Account"])},
      "photo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo 1"])},
      "photo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo 2"])},
      "photo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo 3"])},
      "photo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo 4"])},
      "video1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video 1"])},
      "video2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video 2"])},
      "selectPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Photo"])},
      "selectVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Video"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "selectProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your profile"])},
      "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links of previous works"])},
      "links2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links of previous works"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
      "imageReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo1 is required"])},
      "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo1 is not valid"])},
      "imageMimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo1 must be a file of type: jpeg, png, jpg, gif"])},
      "audioMimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Audio must be a file of type : wav, mp3, mpga, mp4a, aac"])},
      "audioMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Audio size must be less than 30MB"])},
      "imageMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo1 size must be less than 8MB"])},
      "videoMimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video1 must be a file of type: mp4,mov,ogg,qt"])},
      "videoMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video1 size must be less than 50MB"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application has been sent successfully."])}
    }
  },
  "actors": {
    "slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actors-en"])},
    "sliderHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actors-home-en"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the right actor!"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/actors/slider-actors-caption-en.png"])},
    "video": {
      "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/video-thumb.jpg"])}
    },
    "whatsapp_icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/icons/whatsapp-icon-en.png"])},
    "desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Introducing JUMP Casting <br /> The biggest Casting Database In Saudi Arabia, browse a catalog of <span class=\"text-red-500 font-bold\">+500</span> actors from all different ages and nationalities <br /> To watch all the actors audition videos, contact us now on Whatsapp ", _interpolate(_named("whatsapp")), " <br />"])},
    "desc2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you looking for the perfect cast for your next project? JUMP Casting Talent Management Agency is here to help you find the perfect actor for your project with a database of more than <span class=\"text-red-500 font-bold\">+500</span> actors and actresses of all ages and nationalities, you can check out the cast photos on our website.<br /><br /> What distinguishes us from other agencies is that we film an audition for each actor through which he plays a short sketch that shows his ability to act and makes it easier for you to decide to appoint him for your project... You can find an example of an audition that allows the actor to express a large number of feelings, which makes it easier for you to choose and compare between the actors until you find the actor right for you.<br /> <br />You can watch all casting videos by contacting us on WhatsApp by clicking on this link. ", _interpolate(_named("whatsapp")), " <br /><br /> Our team in talent management (the Cast Managers) is ready to receive your requests and answer any questions you may have..Contact us now via this link and let us help you achieve your artistic vision!"])}
  }
}