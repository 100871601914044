<template>
    <div>
    <section class="flex flex-col w-full h-full antialiased bg-black  ">
      <Header></Header>
      <slider class="xl:-mt-20 "></slider>
    </section>
    <section>
                <div class="flex items-center bg-gray-300 justify-center font-oswald rtl:font-cairo text-xl h-20 inset-0 text-center text-red-700">
                <a href="https://drive.google.com/u/2/uc?id=1F86T1hFhiIq__bACaNvAcT8zcxeHfSd4&export=download">{{$t('general.profile.download')}}</a></div>

    </section>
    <section class="flex h-screen w-full">
        <iframe src="https://drive.google.com/file/d/1F86T1hFhiIq__bACaNvAcT8zcxeHfSd4/preview" width="100%" height="100%"></iframe>
        </section>
    </div>
</template>

<script>
import Header from '@/components/header.vue'
import Slider from '@/components/slider.vue'

export default {
  components: {
    Header,
    Slider
  }

}
</script>
