import axios, { AxiosInstance } from 'axios'
// import { cacheAdapterEnhancer } from 'axios-extensions'

const apiClient: AxiosInstance = axios.create({
  baseURL: 'https://jumpnew.jump.sa/api/',
  // baseURL: "http://127.0.0.1:8000/api/",
  headers: {
    'Content-type': 'application/json',
    'Cache-Control': 'max-age=604800'
  }
  // adapter: cacheAdapterEnhancer(axios.defaults.adapter)
})

export default apiClient
