export default {
  "general": {
    "profile": {
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا لتحميل البروفايل"])}
    },
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])},
    "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa"])},
    "home": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUMP هي شركة سعودية رائدة في مجال الدعاية والإعلان والإنتاج الفني، بقاعدة عملاء في الخليج و الشرق الأوسط ، نقدم مجموعة واسعة من الخدمات الإبداعية مثل كتابة السيناريوهات والأفكار، إنتاج و تصوير الإعلانات، رسم الستوري بورد، الانيميشن والموشن جرافيك، تصميم الهوية، التصوير بالدرون وغيره الكثير.          "])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة جمب, شركة jump, خدمات ابداعية, شركة انتاج فني, شركات الدعايه والاعلان, شركة انتاج اعلامي, انتاج فيديو, انتاج موشن جرافيك, وكالة دعاية واعلان, تسويق الكتروني, تسويق رقمي, شركات تسويق في الرياض, شركات التسويق في السعودية, للدعاية والاعلان, تصميم هوية, تصميم فيديو, تصميم لوجو, تصميم موشن جرافيك, تعليق صوتي,  "])}
    },
    "video": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jump sa"]), _normalize(["Video Production "])])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتاج فيديو احترافي، فيديو مصور ، كتابة السيناريو، رسم الستوري بورد، تعيين الممثلين والكومبارس، اختيار مودل ممثلين، تحرير الفيديو ، التصوير باستخدام احدث التقنيات ، تصوير على خلفية خضراء، تصوير كروما، تاجير مواقع التصوير، تصوير في الرياض، كتابة محتوى اعلاني، تعليق صوتي، اختيار المواهب"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتاج فيديوهات, انتاج فيديو, انتاج فني, شركة انتاج فني, شركات الانتاج الفني, انتاج الفيديوهات, تصميم مقاطع فيديو, تصميم فيديو اعلاني, مونتاج فيديو احترافي, تصميم فيديو, تصميم فيديو احترافي, "])}
    },
    "motion": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jump sa"]), _normalize(["Motion Graphics "])])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتاج موشن جرافيك من كتابة السيناريو، إنتاج انيميشن ، تصميم موشن ، تصميم غرافيك، ورسم الستوري بورد، تسجيل التعليق الصوتي، تصميم المشاهد، التحريك والانيميشن، تسجيل التعليق الصوتي، الموسيقى والمؤثرات"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم فيديو موشن, تصميم موشن جرافيك, انتاج موشن جرافيك, تصميم فيديو جرافيك, انتاج انيميشن, تصميم انيميشن, اعلانات موشن جرافيك, تصميم موشنجرافيك, انتاج موشن غرافيك, انتاج موشن قرافيك, "])}
    },
    "branding": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jump sa"]), _normalize(["Branding "])])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صمم هويتك التجارية، هويتك البصرية ، تصميم اللوجو، تصميم لوجو تطبيقات الهوية، تصميم كرت العمل، أوراق رسمية، تصميم بروفايل، تصميم بريزنتيشن"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم هوية تجارية, تصميم علامة تجارية, تصميم هوية, تصميم لوجو, تصميم لوجوات, تصميم لوجوهات, تطبيقات الهوية, تصميم بروفايل, تصميم برزنتيشن, تصميم بريزنتيشن, ارشادات الهوية,  "])}
    }
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنتاج الفيديو"])},
    "motion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم المتحركة"])},
    "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم الهوية"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف التعريفي"])},
    "casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المواهب"])},
    "actors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أختر ممثل"])},
      "actor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أختر ممثل"])}
    },
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])},
    "cast": {
      "ActorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل ممثل"])},
      "DirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مخرج"])},
      "VgrapherForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مصور فيديو"])},
      "Photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مصور فوتوغرافي"])},
      "editorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل محرر فيديو"])},
      "stylistForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مصمم ملابس"])},
      "aDirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مدير انتاج"])},
      "voiceForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل معلق صوتي"])},
      "modelForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مودل"])}
    },
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "section": {
    "Video Production": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("nav.video")])},
    "Motion Graphics": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("nav.motion")])},
    "Behind the Scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلف الكواليس"])},
    "Branding": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("nav.branding")])},
    "Bvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلف الكواليس"])},
    "chooseActors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ممثل"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن:"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='text-red-500'>JUMP</span> هي شركة سعودية رائدة في مجال الدعاية والإعلان والإنتاج الفني، بقاعدة عملاء في الخليج و الشرق الأوسط ، نقدم مجموعة واسعة من الخدمات الإبداعية مثل كتابة السيناريوهات والأفكار، إنتاج و تصوير الإعلانات، رسم الستوري بورد، الانيميشن والموشن جرافيك، تصميم الهوية، التصوير بالدرون وغيره الكثير."])}
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت تريد إنتاج إعلانات للتليفزيون أو الإنترنت، أو فيلماً وثائقياً لشركتك، أو اردت انتاج الرسوم المتحركة 'موشن جرافيك'، او تصميم هويتك التجارية فقد وصلت للمكان المناسب! فنحن ننتج كل ما سبق بجودة رائعة و محتوى جذاب لا يمكن نسيانه."])}
  },
  "video": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراحل انتاج الفيديو"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/video-ar.svg"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عندما يآتي الامر لانتاج الفيديوهات كل ما عليك هو إخبارنا بأهدافك وسوف نقوم بتحويلها إلى محتوى جذاب ومشاهد رائعة تسهل وصول رسائلك إلى قلب جمهورك! تجد بالاسفل امثلة على فيديوهات مصورة من انتاجنا من كتابة الفكرة والسيناريو حتى المونتاج والتسليم."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتابة السيناريو"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم الستوري بورد"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار الممثلين"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار اللوكيشن"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصوير"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل التعليق الصوتي"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المونتاج وإضافة الموسيقى"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الجرافيك / المؤثرات البصرية"])}
      }
    ]
  },
  "motion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراحل انتاج الموشن جرافيك"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/motion-ar.svg"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنيميشن أو الموشن جرافيك هي طريقة رائعة لزيادة وعي الجمهور بعلامتك التجارية، أو تعريفهم بمنتج بعينه، أو تحويل زوار موقعك الإلكتروني إلى زبائن.. اجذبهم بطريقة مبدعة! تجد بالاسفل أمثلة على موشن جرافيك من انتاجنا من كتابة الفكرة والسيناريو حتى التحريك والتسليم."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتابة السيناريو"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم الستوري بورد"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل التعليق الصوتي"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحريك"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الموسيقى والمؤثرات"])}
      }
    ]
  },
  "branding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراحل تصميم الهوية التجارية"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/branding-ar.svg"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللوجو او الهوية هي أول ما يلاحظه جمهورك حتى قبل أن يتعرف على خدماتك بشكل كامل، تصميم علامة تجارية ابداعية  متوافقة مع هويتك التجارية هو أمر حتمي لجذب عميلك المستهدف! تجد بالأسفل أمثلة على تصميم هويات تجارية مع تطبيقاتها"])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يقوم العميل بتعبئة استبيان بسيط عن منتجه/شركته"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم اكثر من خيار للوجو"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم تطبيقات الهوية"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم الدليل الإرشادي للهوية"])}
      }
    ]
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are always ready to help you. Feel free to contact us for any questions or inquiries you may have."])},
    "addess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po.box 826 Riyadh 11421 KSA"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+966 540 233 695"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
      "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسمك هنا"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
      "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع بريدك الالكتروني هنا"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الرسالة"])},
      "subjectPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عنوان الرسالة هنا"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتك"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استلام رسالتك بنجاح، سنتواصل معكم بعد قليل"])}
    }
  },
  "casting": {
    "actorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل ممثل"])},
    "directorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج نموذج تسجيل مخرج"])},
    "VgrapherTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل مصور فيديو"])},
    "photographerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل مصور فوتوغرافي"])},
    "editorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل محرر فيديو"])},
    "stylistTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل مصمم ملابس"])},
    "aDirectorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل مدير انتاج"])},
    "voiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل معلق صوتي"])},
    "modelTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل مودل"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "slider": {
      "ActorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ActorForm"])},
      "DirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DirectorForm"])},
      "VgrapherForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VgrapherForm"])},
      "Photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographer"])},
      "editorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editorForm"])},
      "stylistForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stylistForm"])},
      "aDirectorForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aDirectorForm"])},
      "voiceForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voiceForm"])},
      "modelForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelForm"])}
    },
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمر"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطول"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوزن"])},
      "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجال دراستك"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدى اجادتك للغة الانجليزية"])},
      "voiceEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي لغات التعليق الصوتي تجيد؟"])},
      "voiceEnglish2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تتقن الدوبلاج (التمثيل الصوتي)"])},
      "homeStudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك ستوديو مصغر؟"])},
      "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم ساعه تحتاج لتسليم المقطع الصوتي بعد استلامك للنص؟"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر جنسيتك"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
      "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي أنواع كاميرات تستخدم"])},
      "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي معدات التصوير التي تملكها"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك الخبرة في التصوير بصيغة LOG"])},
      "experience2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع علامة بجانب الأشياء التي تجيد تصويرها"])},
      "experienceEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي برنامج مونتاج تستخدم؟"])},
      "laptop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تمتلك لاب توب؟"])},
      "laptopType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما نوعه؟"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللهجة"])},
      "vimeo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابك على Vimeo"])},
      "insta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابك على Instagram"])},
      "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابك على Youtube"])},
      "soundcloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابك على Sound Cloud"])},
      "photo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أَضف صورة 1"])},
      "photo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة 2"])},
      "photo3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة 3"])},
      "photo4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة 4"])},
      "video1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو 1"])},
      "video2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو 2"])},
      "audio1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صوت 1"])},
      "audio2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صوت 2"])},
      "selectPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر صورة"])},
      "selectVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ملف فيديو"])},
      "selectAudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ملف صوت من اعمالك السابقة"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بروفايل"])},
      "selectProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع بروفايلك"])},
      "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط فيديو لأعمال سابقة"])},
      "links2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط تصوير فوتوغرافي لمشاريع سابقة"])},
      "linksStylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط لمشاريع أزياء سابقة من تصميمك وتنفيذك"])},
      "linksAudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط صوت لأعمالك السابقة في التعليق الصوتي"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
      "imageReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة 1 مطلوبة"])},
      "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب اختيار نوع صورة"])},
      "imageMimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تكون الصورة من نوع : jpeg, png, jpg, gif"])},
      "imageMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الصورة يجب ان تكون أقل من 8 ميغابايت"])},
      "audioMimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب ان تكون الفيديو من نوع : wav, mp3, mpga, mp4a, aac"])},
      "audioMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملف الصوت يجب ان يكون أقل من 30 ميغابايت"])},
      "videoMimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب ان تكون الفيديو من نوع : mp4, avi, mpg, mpeg, ogg"])},
      "videoMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الفيديو يجب ان يكون أقل من 50 ميغابايت"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسالة النموذج بنجاح، سنقوم بالتواصل معكم في أقرب وقت ممكن"])}
    }
  },
  "actors": {
    "slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actors"])},
    "sliderHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actors-home"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الممثل المناسب!"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/actors/slider-actors-caption.png"])},
    "video": {
      "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/video-thumb.jpg"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://youtube.com/shorts/6FzPlGjPVxM"])}
    },
    "whatsapp_icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/icons/whatsapp-icon.png"])},
    "desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" نفخر باطلاق JUMP كاستنق! <br />اكبر قاعدة بيانات ممثلين في المملكة، اختر ممثلك المناسب من بين أكثر من <span class=\"text-red-500 font-bold\"> +500 </span> ممثل من مختلف الأعمار والجنسيات  <br /> ولمشاهدة جميع فيديوات الأداء الرجاء التواصل معنا على الواتساب ", _interpolate(_named("whatsapp")), "<br /> <br />"])},
    "desc2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" هل تبحث عن الممثلين المثاليين لمشروعك القادم؟ وكالة JUMP Casting لإدارة المواهب هنا لمساعدتك في العثور على الممثل المثالي لمشروعك من خلال قاعدة بيانات تزيد عن <span class=\"text-red-500 font-bold\"> 500+ </span> ممثل وممثلة من جميع الأعمار والجنسيات ، يمكنكم الاطلاع على صور الممثلين على موقعنا.<br /><br />  ما يميزنا عن باقي الوكالات هو قيامنا بتصوير تجربة اداء لكل ممثل يقوم من خلالها بتمثيل سكتش قصير يظهر قدرته على التمثيل ويسهل عليكم اتخاذ القرار بتعيينه لمشروعكم…  تجدون مثال لتجربة أداء تسمح للمثل باخراج عدد كبير من المشاعر مما يسهل عليك الاختيار والمفاضلة بين الممثلين حتى تجد الممثل المناسب لك.<br />  يمكنك مشاهدة جميع فيديوات الكاستينق عبر التواصل معنا على الواتساب بالضغط على هذا الرابط ", _interpolate(_named("whatsapp")), "<br /> <br />  فريقنا في إدارة المواهب (الـ Cast Managers) جاهزين لاستقبال طلباتكم والاجابة عن اي أسئلة لديكم ..تواصل معنا الان عبر هذا الرابط ودعنا نكون عونا لك في تحقيق رؤيتك الفنية!"])}
  }
}