
// import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
export default {
  name: 'AboutUs',
  data () {
    return {
      isHidden: true
    }
  },
  methods: {},
  components: {
    // CollapseTransition
  }
}
