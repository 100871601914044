<template>
    <div class="relative flex flex-col justify-center rtl:font-cairo font-oswald items-start text-left rtl:text-right text-gray-300 pt-5 pb-10 space-y-2 ">
        <p class="text-xl rtl:text-base font-light text-justify "> {{ $t(''+categoryContent+'.desc') }}   </p>
        <h2 class="bold text-2xl font-bold text-red-600 py-5">{{ $t(''+categoryContent+'.title') }} </h2>
        <div class="flex flex-row flex-wrap h-full w-full space-x-5 rtl:space-x-reverse sm:space-x-5 inset-0  justify-center items-start stages" >
          <div v-for="(stage, index) in stages" :key="stage.name" class="relative flex flex-col xl:flex-shrink  w-3/12 lg:w-2/12 py-4 items-start justify-start" >
            <!-- Arrow -->
            <svg v-if="index < stages.length-1" xmlns="http://www.w3.org/2000/svg" class="arrow h-4 w-4 sm:h-6 sm:w-6 absolute transform rtl:rotate-180 right-1 sm:right-1/4 rtl:left-1 rtl:right-auto sm:rtl:left-1/4 top-10 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
            </svg>
            <!-- icon -->
            <img class="flex h-10 w-10 sm:h-12 sm:w-12 "  :src="require(`@/assets/icons/${categoryContent}/${stage.icons}`)" :alt="$t(categoryContent+'.stages.'+index+'.name')">
            <!-- Text -->
            <div class="flex flex-row w-full items-start py-5 space-x-2 rtl:space-x-reverse justify-start">
                <div class="flex items-end rtl:items-start justify-center relative w-4 h-5 bg-red-500">
                    <span class="text-md font-bold absolute text-black -bottom-1.5 ">{{index+1}}</span>
                </div>
                <h3 class="w-auto text-xs -mt-1 sm:text-md md:text-lg"> {{ $t(categoryContent+'.stages.'+index+'.name')}}</h3>
            </div>
          </div>

        </div>
      </div>
</template>
<script>
export default {
  name: 'Headin' +
    'g',
  props: ['title','cat'],

  data () {
    return {

    }
  },
  computed: {
    stages () {
      if (this.cat ===  '1') {
        return this.tvideo.stages
      } else if (this.cat === '3') {
        return this.tmotion.stages
      } else if (this.cat === '4') {
        return this.tbranding.stages
      } else {
        return []
      }
    },
    categoryContent () {
      if (this.cat === '1') {
        // return this.video
        return 'video'
      } else if (this.cat === '3') {
        return 'motion'
      } else if (this.cat === '4') {
        return 'branding'
      } else {
        return ''
      }
    },

    tvideo () {
      return {
        stages: [
          {
            icons: '01.png'
          },
          {
            icons: '02.png'
          },
          {
            icons: '03.png'
          },
          {
            icons: '04.png'
          },
          {
            icons: '05.png'
          },
          {
            icons: '06.png'
          },
          {
            icons: '07.png'
          },
          {
            icons: '08.png'
          }
        ]
      }
    },
    tmotion () {
      return {

        stages: [
          {
            icons: '01.png'
          },
          {
            icons: '02.png'
          },
          {
            icons: '03.png'
          },
          {
            icons: '04.png'
          },
          {
            icons: '05.png'
          }
        ]
      }
    },
    tbranding () {
      return {

        stages: [
          {
            icons: '01.png'
          },
          {
            icons: '02.png'
          },
          {
            icons: '03.png'
          },
          {
            icons: '04.png'
          }
        ]
      }
    }
  }
}
</script>
