<template>
  <div class="container flex bg-opacity-40 text-white ">
    <section class="relative w-full py-0 lg:py-5 rtl:font-cairo font-oswald" >

      <div class="flex flex-col items-center justify-between px-2 lg:px-10 mx-auto max-w-7xl xl:px-5 lg:flex-row">

        <div class="flex flex-col items-center justify-center w-full px-10 pt-5 pb-20 lg:pt-20 lg:flex-row">

          <div class="relative z-10 w-full max-w-2xl mt-5 lg:mt-0 ">
              <h3 class="py-3 text-xl md:text-2xl text-red-600 rtl:font-cairo font-oswald font-bold">{{ $t('casting.stylistTitle') }}</h3>

            <div class="relative z-10 flex flex-col items-start justify-start ">
              <form ref="contactjump" @submit.prevent="submit" class="w-full">

                <div class="relative w-full mt-6 space-y-6 lg:space-y-8">
<!--Name -->
                  <div class="relative flex flex-row justify-center items-center">
                    <label class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.name') }}</label>
                    <input required v-model="formData.name" type="text"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500"
                      >
                  </div>
<!--Age-->
                  <div class="relative flex flex-row justify-center items-center">
                    <label class="px-2 ml-2 font-light w-24 md:w-32 lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.age') }}</label>
                    <input required v-model="formData.age" type="number" name="age"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500"
                      >
                  </div>
<!--Phone-->
                  <div class="relative flex flex-row justify-center items-center">
                    <label class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.phone') }}</label>
                    <input required v-model="formData.phone" type="number" name="phone"
                           class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500"
                    >
                  </div>
<!--Nationality-->
                  <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.nationality') }}</label>
                   <select v-model="formData.nationality"
                    class="
                    block w-full px-4 py-2 lg:py-2 mt-2 text-white
                    bg-transparent border border-gray-200 rounded-md focus:bg-transparent  focus:border-red-500
                    transition
                    ease-in-out
                    focus:outline-none" aria-label=".form-select-sm example">>
                      <option v-for="country in countries" :key="country.value" :value="country.label" class="bg-black">
                        {{ country.label }}
                      </option>
                    </select>

                  </div>
<!--City-->
                   <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.city') }}</label>
                   <select v-model="formData.city"
                    class="
                    block w-full px-4 py-2 lg:py-2 mt-2 text-white
                    bg-transparent border border-gray-200 rounded-md focus:bg-transparent  focus:border-red-500
                    transition
                    ease-in-out
                    focus:outline-none" aria-label=".form-select-sm example">>
                      <option v-for="city in cities" :key="city.value" :value="city.label" class="bg-black">
                        {{ city.label }}
                      </option>
                    </select>

                  </div>
<!--Study-->
                  <div class="relative flex flex-row justify-center items-center">
                    <label class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.study') }}</label>
                    <input required v-model="formData.study" type="text"
                           class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500"
                    >
                  </div>


<!--Insta-->
                  <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.insta') }}</label>
                    <input v-model="formData.insta" type="text" name="insta"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500">
                  </div>


                  <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.linksStylist') }}</label>
                    <input v-model="formData.links1" type="text" name="links1"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500">
                  </div>
                  <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  "></label>
                    <input v-model="formData.links2" type="text" name="links2"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500">
                  </div>
                  <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  "></label>
                    <input v-model="formData.links3" type="text" name="links3"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500">
                  </div>
                  <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  "></label>
                    <input v-model="formData.links4" type="text" name="links4"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500">
                  </div>

                   <div class="relative flex flex-row justify-center items-center">
                    <label
                      class="px-2 ml-2 w-24 md:w-32 font-light lg:font-medium text-gray-400 bg-transparent  ">{{ $t('casting.form.note') }}</label>
                      <textarea v-model="formData.note" name="note" id="note" cols="30" rows="10"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-transparent   border border-gray-600 rounded-md focus:outline-none focus:border-red-500">
                      </textarea>
                  </div>

                  <div class="relative flex flex-row justify-center items-center">
                    <button    type="submit"  v-bind:disabled="hasVoted"
                      class="inline-block w-auto px-5 py-2 lg:py-4 text-xl font-light lg:font-medium text-center text-white transition duration-200 bg-red-500 rounded-lg hover:bg-red-800 ease"
                      >{{ $t('casting.form.submit') }}
                      <svg v-if="hasVoted" xmlns="http://www.w3.org/2000/svg" class="animate-spin h-5 w-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      </button>

                  </div>

                </div>
                <div class="success text-center justify-center items-center"  v-if="isSuccess">
                  {{ $t('casting.form.success') }}
                </div>
                <div v-if="errors" class="justify-center items-center text-center">
                  <div v-for="error in errors" :key="error.id" class="alert alert-danger justify-center items-center text-center">

                </div>
                </div>
              </form>

            </div>

          </div>
        </div>

      </div>

    </section>

  </div>
</template>

<script>
import axios, {
  AxiosInstance
} from 'axios'
import http from '@/http-common'
import _ from 'lodash'

import { CountriesList } from '@/services/Countries'

export default {
  name: 'stylistForm',

  data () {
    return {
      formData: {
        name: '',
        age: '',
        phone: '',
        study:'',
        nationality: null,
        city: '',

        insta: '',
        links1: '',
        links2: '',
        links3: '',
        links4: '',
        note: ''
      },
      message: '',
      response: '',
      isSuccess: false,
      errors: null,
      hasSubmited: false,
    }
  },
  computed: {
    hasVoted () {
      return this.hasSubmited
    },
countries () {
      const list = CountriesList.forCountries('registerLocale').getNames(this.$i18n.locale, { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },
    cities () {
      const list = CountriesList.forCountries('registerLocaleCities').getNamesCities(this.$i18n.locale, { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    }
  },
  methods: {
    resetForm () {
      this.formData.name = ''
      this.formData.age = ''
      this.formData.nationality = ''
      this.formData.city = ''
      this.formData.study = ''
      this.formData.insta = ''

      this.formData.phone = ''
      this.formData.links1 = ''
      this.formData.links2 = ''
      this.formData.links3 = ''
      this.formData.links4 = ''
      this.formData.note = ''


    },

    submit () {
      this.errors = null
      this.hasSubmited = true
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      const formData = new FormData()
      _.each(this.formData, (value, key) => {
        value === undefined ? formData.append(key, '') : formData.append(key, value)
      })
       // console.log(...formData)

      http.post('/stylist-submit', formData, config).then(response => {
         // console.log(response.data)
        if (response.data.success) {
          this.isSuccess = true
          this.hasSubmited = false
          this.resetForm()
        } else {
          this.hasSubmited = false
          this.errors = response.data
        }
        // this.isSuccess = true
        // this.hasSubmited = false
        // this.resetForm()
      }).catch(err => {
        this.hasSubmited = false

        if (err.response.status === 422) {
          this.errors = []
          _.each(err.response.data.errors, error => {
            _.each(error, e => {
              this.errors.push(e)
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
  [type=file] {
    position: absolute;
    filter: alpha(opacity=0);
    opacity: 0;
}
#casting {
  background-image: url('../../assets/casting.jpg');
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;

}
input,
[type=file] + label {
  border: 1px solid #CCC;
  border-radius: 3px;
  padding: 10px;
  margin: 0;

  position: relative;
}
[type=file] + label {
  text-align: center;
  /* Decorative */
  background: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 0  0  0 10px;
}
[type=file] + label:hover {
  background: #c41111;
}
input:checked ~ .radio {
  color:white;
  background-color: rgb(190, 10, 10);
}
input:checked ~ .checkbox {
  color:white;
  background-color: rgb(190, 10, 10);
}
</style>
