// import { createApp } from 'vue'
import { createApp } from 'vue/dist/vue.esm-bundler';

import App from './App.vue'

import mitt from 'mitt';                  // Import mitt
const emitter = mitt();                   // Initialize mitt

import store from '@/store/index.js'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueScreen from 'vue-screen'
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import vueScrollto from 'vue-scrollto'

import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import VueEasyLightbox from 'vue-easy-lightbox'
import i18n from '@/i18n'
import { createMetaManager,defaultConfig, plugin as metaPlugin } from 'vue-meta'
import lazyPlugin from 'vue3-lazy'


const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
});

const app = createApp(App);
app.provide('emitter', emitter);
lazyPlugin.install(app, {
  loading: require(`@/assets/icons/thumb-logo-jumpsa.png`),
  error: require(`@/assets/icons/thumb-logo-jumpsa.png`)
})

app.use(VueAxios, axios)
  .use(i18n)
  .use(router)
  .use(store)
  .use(VueModal)
  .use(VueScreen)
  .use(vueScrollto)
  .use(VueEasyLightbox)
  .use(metaPlugin)
  .use(metaManager)
  .mount("#app");

  declare global {
    interface Window {
      snapSaveState?: any;
    }
  }

  window.snapSaveState = () => {
    // enable client-side hydration once the page has been prerendered
    document.querySelector('#app')!.setAttribute('data-server-rendered', 'true');

    // // remove scripts added to head by webpack; fix async race condition issue
    // const scripts = document.head.getElementsByTagName('script');
    // for (let i = 0; i < scripts.length; i += 1) { // forEach didn't work for some bizarre reason
    //   const el = scripts[i];

    //   if (el.async && el.charset === 'utf-8') {
    //     // console.warn('REMOVING:', el.src); // eslint-disable-line no-console
    //     el.remove();
    //   }
    // }

    // // remove comments (react-snap config can't take RegExp so do it custom)
    // const html = document.documentElement;
    // const noComments = html.innerHTML.replace(/<!--(?!\[if|-->)[\s\S]*?-->/g, '');
    // html.innerHTML = noComments;
  };
