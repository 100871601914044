<template>
<metainfo></metainfo>
  <div id="start" class=" bg-black" >

    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="route.path"></component>
      </transition>
    </router-view>
  <!-- ScrollUp -->
  <vue-scroll-up custom-class="my-scroll-up" />

  <div class="fixed flex items-end h-full right-5 bottom-5 z-10">
    <a target="_blank" @click="goog_report_conversion ()" href="https://api.whatsapp.com/send?phone=+966540233695&text=مرحبا بك فضلا كتابة استفسارك وسيتم الرد عليك في أقرب وقت"
    class="">
    <svg viewBox="0 0 32 32" class="whatsapp-ico"><path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd"></path></svg>
    </a>
  </div>

  </div>
  <vmodal></vmodal>

</template>

<script>
import VueScrollTo from 'vue-scrollto'
import vmodal from '@/components/VModal.vue'

import VueScrollUp from 'vue-scroll-up'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
export default {
  name: 'App',
  data () {
    return {
    }
  },
  setup () {
    const { t } = useI18n({})
    const title = t('general.home.title')
    const desc = t('general.home.description')
    const keys = t('general.home.keys')

    useMeta({
      title: `${title}`,
      description: `${desc}`,
      meta: [
        {
          name: 'keywords',
          content: `${keys}`
        },
        { property: 'og:title', content: `${title}` },
        { property: 'og:keywords', content: `${keys}` },

        { property: 'og:site_name', content: 'Jump sa' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },

        { property: 'og:url', content: 'https://jump.sa' },
        { property: 'og:description', content: `${desc}` },

        // Twitter card
        { name: 'twitter:card', content: `${desc}` },
        { name: 'twitter:site', content: 'https://jump.sa' },
        { name: 'twitter:title', content: `${title}` },
        { name: 'twitter:description', content: `${desc}` },
        // Your twitter handle, if you have one.
        { name: 'twitter:creator', content: '@Jump sa' },

        // Google / Schema.org markup:
        { itemprop: 'name', content: `${title}` },
        { itemprop: 'description', content: `${desc}` }
      ]

    })
  },
  methods: {
    scrollUp () {
      VueScrollTo.scrollTo('#home')
    },
    /* <![CDATA[ */
    goog_snippet_vars () {
      var w = window
      w.google_conversion_id = 'AW-794211429'
      w.google_conversion_label = 'vSPPCPrwoYsDEOXo2voC'
      w.google_conversion_value = 2.00
	    w.google_conversion_currency = 'USD'
      w.google_remarketing_only = false
    },
    // DO NOT CHANGE THE CODE BELOW.
    goog_report_conversion (url) {
      this.goog_snippet_vars()
      window.google_conversion_format = '3'
      // eslint-disable-next-line no-new-object
      var opt = new Object()
      opt.onload_callback = function () {
        if (typeof (url) !== 'undefined') {
          // window.location = url
        }
      }
      // eslint-disable-next-line camelcase
      var conv_handler = window.google_trackConversion
      // eslint-disable-next-line camelcase
      if (typeof (conv_handler) === 'function') {
        conv_handler(opt)
      }
    }
    /* ]]> */

  },

  components: {
    VueScrollUp,
    vmodal
  }

}
</script>

<style lang="css">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .toolbar-btn__rotate {display: none;}

  .whatsapp-ico{
    fill: white;
    width: 45px;
    height: 45px;
    padding: 3px;
    background-color: #4dc247;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
    /* box-shadow: 2px 2px 11px rgba(0,0,0,0.7); */
    position: fixed;
    bottom: 20px;
    right : 20px;
    z-index: 10;
}

.whatsapp-ico:hover{
    box-shadow: 2px 2px 11px rgba(0,0,0,0.7);
}

.my-scroll-up {
  align-items: center;
  background-color: rgba(231, 1, 1, 0.658);
  border: rgba(231, 0, 0, 0.658);
  border-radius: 100px;
  bottom: 80px;
  color: white;
  cursor: pointer;
  display: flex;
  height: 45px;
  justify-content: center;
  position: fixed;
  right: 22px;
  width: 45px;
  z-index: 99;
}

.my-scroll-up::after {

}

.my-scroll-up::before {
  content: '';
    display: block;
    width: 15px;
    height: 15px;
    top: 60%;
    left: 66%;
    margin: 0 auto;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-width: 3px 3px 0 0;
    position: absolute;
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
    margin: -10px 0 0 -15px;
}

@keyframes top {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 450px) {
  .vue-scroll-up {
    bottom: 10px;
    transform: translateX(5px) scale(0.7);
  }
}
</style>
