<template>
  <div class="container flex bg-black text-white ">
    <section class="relative w-full py-0 lg:py-5 rtl:font-cairo font-oswald">

      <div class="flex flex-col items-center justify-between px-2 lg:px-10 mx-auto max-w-7xl xl:px-5 lg:flex-row">

        <div class="flex flex-col items-start w-full px-10 pt-5 pb-20 lg:pt-20 lg:flex-row">
          <div class="relative  max-w-md bg-cover lg:max-w-2xl lg:w-5/12">
            <div class="relative flex flex-col  h-full lg:pr-10">
              <h1 class="py-3 text-3xl md:text-5xl text-red-600 rtl:font-cairo font-oswald font-bold">{{ $t('contact.title') }}</h1>
              <img class="w-14 md:w-20 mt-5" :src="require(`@/assets/icons/logo.jpg`)" alt="Jump sa logo">
              <ul class="w-full items-start py-5 space-y-4 text-gray-300 font-light text-sm md:text-xl lg:text-xl">

                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-flex text-red-600" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <span class="px-3 inline-flex" style="direction:ltr">{{ $t('contact.phone') }}</span>

                </li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-flex text-red-600" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                  </svg>
                  <span class="px-3 inline-flex">alsaloli@jump.sa <br> heba@jump.sa <br> ahmed.r@jump.sa</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="relative z-10 w-full max-w-2xl mt-5 lg:mt-0 lg:w-7/12">
            <div class="relative z-10 flex flex-col items-start justify-start ">
              <form ref="contactjump" class="w-full" @submit.prevent="submit">
                <!-- <input type="hidden" name="_token" :value="csrf"> -->

                <div class="relative w-full mt-6 space-y-6 lg:space-y-8">
                  <div class="relative">
                    <label class="absolute px-2 ml-2 -mt-3 font-light lg:font-medium text-gray-400 bg-black">{{ $t('contact.form.name') }}</label>
                    <input required v-model="name" type="text"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-black border border-gray-600 rounded-md focus:outline-none focus:border-red-500"
                      :placeholder="$t('contact.form.namePlace')">
                  </div>

                  <div class="relative">
                    <label class="absolute px-2 ml-2 -mt-3 font-light lg:font-medium text-gray-400 bg-black">{{ $t('contact.form.email') }}</label>
                    <input required v-model="email" type="text" name="email"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-black border border-gray-600 rounded-md focus:outline-none focus:border-red-500"
                      :placeholder="$t('contact.form.emailPlace')">
                  </div>

                  <div class="relative">
                    <label
                      class="absolute px-2 ml-2 -mt-3 font-light lg:font-medium text-gray-400 bg-black">{{ $t('contact.form.subject') }}</label>
                    <input required v-model="subject" type="text" name="subject"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-black border border-gray-600 rounded-md focus:outline-none focus:border-red-500">
                  </div>
                  <div class="relative">
                    <label
                      class="absolute px-2 ml-2 -mt-3 font-light lg:font-medium text-gray-400 bg-black">{{ $t('contact.form.message') }}</label>
                    <textarea required v-model="message" name="message" id="" cols="10" rows="5"
                      class="block w-full px-4 py-2 lg:py-4 mt-2 text-base placeholder-gray-600 bg-black border border-gray-600 rounded-md focus:outline-none focus:border-red-500"></textarea>

                  </div>
                  <div class="relative">
                    <button type="submit"  v-bind:disabled="hasVoted"
                      class="inline-block w-full px-5 py-2 lg:py-4 text-xl font-light lg:font-medium text-center text-white transition duration-200 bg-red-500 rounded-lg hover:bg-red-800 ease"
                      >{{ $t('contact.form.submit') }}
                      <svg v-if="hasVoted" xmlns="http://www.w3.org/2000/svg" class="animate-spin h-5 w-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      </button>

                  </div>

                </div>
                <div class="success" v-if="isSuccess">
                  {{ $t('contact.form.success') }}
                </div>
              </form>

            </div>

          </div>
        </div>

      </div>

    </section>

  </div>
</template>

<script>

import axios, {
  AxiosInstance
} from 'axios'
import http from "@/http-common";

export default {
  name: 'ContactUs',
  data () {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      response: '',
      isSuccess: false,
      hasSubmited: false

    }
  },
  computed: {
    hasVoted () {
      return this.hasSubmited
    }
  },
  methods: {
    submit () {
      this.hasSubmited = true
      http.post('/send-email', {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      }).then(response => {
        this.isSuccess = true
        this.hasSubmited = false
        this.resetForm()
      }).catch(error => {
        console.error(error.response.data)
        this.hasSubmited = false

      })
    },
    resetForm () {
      this.name = ''
      this.email = ''
      this.subject = ''
      this.message = ''
    }
  }
}
</script>