
import {
  Options
} from '@splidejs/splide'
import {
  Splide,
  SplideSlide
} from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/splide.min.css'

export default {

  name: 'Clients',

  data () {
    return {
      clients: [{
        title: '',
        content: '',
        image: 'client01.png'
      },
      {
        title: '',
        content: '',
        image: 'client04.png'
      },
      {
        title: '',
        content: '',
        image: 'client12.png'
      },

      {
        title: '',
        content: '',
        image: 'client03.png'
      },

      {
        title: '',
        content: '',
        image: 'client06.jpg'
      },

      {
        title: '',
        content: '',
        image: 'client07.png'
      },

      {
        title: '',
        content: '',
        image: 'client11.png'
      },

      {
        title: '',
        content: '',
        image: 'client08.png'
      }

        // Other slides.
      ]
    }
  },
  methods: {

  },
  components: {
    Splide,
    SplideSlide,

  },
  setup () {
    const options: Options = {
      type: 'loop',
      rewind: true,
      autoplay: true,
      pauseOnHover: true,
      arrows: true,
      pagination: false,
      drag: 'free',
      perPage: 5,
      perMove: 1,
      autoWidth: false,
      padding: {
        left: 0,
        right: 0
      },
      gap: '1rem',
      easing: 'ease',
      speed: 200,
      direction: 'rtl',

      breakpoints: {
        1024: {
          perPage: 4
        },
        768: {
          perPage: 3
        }

      }
    }
    return {
      options
    }
  }
}
