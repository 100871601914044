
export class CountriesList {
  static forCountries (method) {
    const countries = require('i18n-iso-countries')
    countries[method](require('i18n-iso-countries/langs/en.json'))
    countries[method](require('i18n-iso-countries/langs/ar.json'))

    return countries
  }

  static forCities () {
    const cities = require('i18n-iso-countries')
    cities.registerLocaleCities(require('i18n-iso-countries/langs/en.json'))
    cities.registerLocaleCities(require('i18n-iso-countries/langs/ar.json'))

    return cities
  }
}

// eslint-disable-next-line new-cap
