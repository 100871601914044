<template>
  <div>
    <!-- all props & events -->
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      :visible="$store.state.isLightBoxShow"
      :imgs="`${imagePath}/${$store.state.main_image}`"
      :index="index"
      @hide="$store.commit('closeLightBox')"
    ></vue-easy-lightbox>
  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      imagePath: 'https://jumpnew.jump.sa/storage/'

    }
  },
  methods: {
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    }
  }
}
</script>
