
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { defineComponent, reactive, ref, computed } from 'vue'

import { Options } from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'

import { Video } from '@splidejs/splide-extension-video'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import { prop } from 'vue-class-component'
import {useStore} from "vuex";
import store from '@/store'
import router from "@/router";


export default defineComponent({

  name: 'Slider',
  props: ['cat', 'location', 'videoID', 'sliderCat'],

 setup () {
    const options = reactive<Options>({
      rewind: true,
      autoplay: true,
      pauseOnHover: false,
      arrows: false,
      perPage: 1,
      easing: 'ease',
      speed: 500,
      interval: 5000,
      start: 0,
      lazyLoad: true,
      direction: 'rtl',

      video: {
        autoplay: true,
        loop: false,
        mute: true,
        hideControls: true,
        playerOptions: {
          htmlVideo: {
            playsInline: true,
            muted: true,
            controls: false,
            autoplay: true,
          }
        }

      }
    })


    const onSplideMounted = (Splide: any) => {

      var Autoplay = Splide.Components.Autoplay
      // var Options = Splide.Options
      const v:any = <HTMLVideoElement>document.querySelector('video')

        Splide.on('video:play', () => {
          Autoplay.pause()
        })

      // if loop is TRUE
      if (v && v.loop === true) {
          const checkVideo = setInterval(() => {
            if (v.currentTime > 2) {
              Autoplay.play()
            }
          }, 1000)
          if (Splide.index === 2) {
              clearInterval(checkVideo)
          }
      }

      // if loop is FALSE
      Splide.on('video:ended', () => {
        Splide.go('>')
        Autoplay.play()
      })
    }
    const onSplideCreated = (Splide: any) => {
    }

    return {
      options,
      extensions: { Video },
      onSplideMounted,
      onSplideCreated,


    }
  },
  data () {
    return {
      splide: this.Splide,

      slides: [
        {
        title: 'Video Production',
        content: '',
        image: 'slider/video-production.jpg',
        caption: 'video.caption',
      },
        {
          title: 'Actors',
          content: '',
          // You can also provide a URL for the image.
          image: 'slider/actors/'+this.$t('actors.sliderHome')+'.jpg',
          caption: this.$t('actors.caption'),
        },
      {
        title: 'Motion graphics',
        content: '',
        image: 'slider/motion-graphics.jpg',
        caption: 'motion.caption',
      },

      {
        title: 'Branding',
        content: '',
        image: 'slider/branding/branding01.jpg',
        caption: 'branding.caption',
      },
      {
        title: 'Video Production',
        content: '',
        // You can also provide a URL for the image.
        image: 'slider/main06.jpg',
        caption: 'video.caption',
      },
        {
          title: 'Casting',
          content: '',
          // You can also provide a URL for the image.
          image: 'slider/casting2.jpg',
          caption: '',
        },




      ],

      // slides for video prodction page
      slidesVideo: [{
        title: 'Video Production',
        content: '',
        // You can also provide a URL for the image.
        image: 'slider/video-production/vp01.jpg',
        caption: 'video.caption',
      },
      {
        title: 'Video Production',
        content: '',
        image: 'slider/video-production/vp02.jpg',
        caption: 'video.caption',
      },
      {
        title: 'Video Production',
        content: '',
        image: 'slider/video-production/vp03.jpg',
        caption: 'video.caption',
      },
      {
        title: 'Video Production',
        content: '',
        image: 'slider/video-production/vp04.jpg',
        caption: 'video.caption',
      }

      ],
      // slides for video prodction page
      slidesMotion: [{
        title: 'Motion Graphics',
        content: '',
        // You can also provide a URL for the image.
        image: 'slider/motion/motion01.jpg',
        caption: 'motion.caption',
      },
      {
        title: 'Motion Graphics',
        content: '',
        image: 'slider/motion/motion02.jpg',
        caption: 'motion.caption',
      },
      ],
      // slides for video prodction page
      slidesBranding: [{
        title: 'Branding',
        content: '',
        // You can also provide a URL for the image.
        image: 'slider/branding/branding01.jpg',
        caption: 'branding.caption',
      },
      {
        title: 'Branding',
        content: '',
        image: 'slider/branding/branding02.jpg',
        caption: 'branding.caption',
      },
      ],
      slidesCasting: [
        {
          title: 'Casting',
          image: '',
          caption: '',
        },
      ],

      slidesActors: [
        {
          title: 'Choose an Actor',
          image: 'slider/actors/'+this.$t('actors.sliderHome')+'.jpg',
        },
      ],
    }
  },
  components: {
    Splide,
    SplideSlide

  },
  methods: {
    slideURL(slide) {
      if(slide.title === 'Actors'){
        router.push({ name: 'actors'});
      }
    },
  },
  computed:{
    sliderVideoId () {
      let store = useStore();

      if (this.cat === '1') {
        store.commit('setSliderVideo', 'video-production-conv.mp4') // 'video-production.mp4')
        } else if (this.cat === '3') {
        store.commit('setSliderVideo', 'motion-conv.mp4') // 'video-production.mp4')
        } else if (this.cat === '0') {
        store.commit('setSliderVideo', 'jump-intro.mp4') // 'video-production.mp4')
        } else {
        store.commit('setSliderVideo', 'jump-intro.mp4') // 'video-production.mp4')
        }
    return {
      sliderVideo: store.state.sliderVideo && require(`@/assets/slider/${store.state.sliderVideo}`)
    }
  },

  sliderContentByCat() {
    if (this.cat === '1') {
      return this.slidesVideo
    }else if (this.cat === '3') {
      return this.slidesMotion
    }else if (this.cat === '4') {
      return this.slidesBranding
    }else if (this.cat === '6') {
      return this.slidesCasting
    }else if (this.cat === '100') {
      return this.slidesActors
    }  else {
      return this.slides
    }
  },
  catName() {
    if (this.cat === '1') {
      return '/video-production'
    }else if (this.cat === '3') {
      return '/motion'
    }else if (this.cat === '4') {
      return '/branding'
    } else {
      return ''
    }
  },

   // RTL
      directionOld:  (): any => {
        return document.documentElement.getAttribute('dir') === 'rtl' ? 'rtl' : 'ltr'
        }

  },



})
