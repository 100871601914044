import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Video from '@/views/Video.vue'
import Motion from '@/views/Motion.vue'
import Branding from '@/views/Branding.vue'
import Casting from '@/views/Casting.vue'
import Actors from '@/views/Actors.vue'

import Profile from '@/views/Portfolio.vue'
import VueScrollTo from 'vue-scrollto';
import Root from '@/router/Root.vue'
import i18n from '@/i18n';

function load(component: any) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
 }

import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/util/i18n/document"


const routes = [
    {
    path: '/:locale(ar|en)/',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: (to: any, from: any, next: any ) =>{
      const locale = to.params.locale;
      const htmlEl = document.querySelector('html')
      if (locale === 'en') {
        htmlEl!.setAttribute('dir', 'ltr')
      }else{htmlEl!.setAttribute('dir', 'rtl')
    }

      if (i18n.global.locale !== locale) {
        i18n.global.locale = locale;
      }
      return next()
    },
    children: [{
        path: '',
        name: 'Home',
        component: Home,
        children: [
          {
            path: 'about',
            name: 'About',
            component: Home,
          },
          {
            path: 'contact',
            name: 'Contact',
            component: Home,
          },
        ]
      },

      {
        path: 'video-production',
        name: 'video-production',
        component: Video,
        props: { pageTitle: 'video', workCat: 1 }

      },

      {
        path: 'actors',
        name: 'actors',
        component: Actors,
        props: { pageTitle: 'actors' }

      },
      {
        path: 'motion-graphics',
        name: 'motion-graphics',
        component: Motion,
        props: { pageTitle: 'Motion Graphics', workCat: 3 }
      },
      {
        path: 'branding',
        name: 'branding',
        component: Branding,
        props: { pageTitle: 'Branding', workCat: 4 }
      },
      {
        path: 'portfolio',
        name: 'portfolio',
        component: Profile,
        props: { pageTitle: 'Portfolio' }
      },
      {
        path: 'casting',
        name: 'casting',
        component: Casting,
        props: (route) => ({ section: route.name }),
        children: [
          {
            path: 'ActorForm',
            name: 'ActorForm',
            component: Casting,
            props: true
          },
          {
            path: 'DirectorForm',
            name: 'DirectorForm',
            component: Casting,
            props: true
          },
          {
            path: 'VgrapherForm',
            name: 'VgrapherForm',
            component: Casting,
            props: true
          },
          {
            path: 'Photographer',
            name: 'Photographer',
            component: Casting,
            props: true
          },
          {
            path: 'editorForm',
            name: 'editorForm',
            component: Casting,
            props: true
          },
          {
            path: 'stylistForm',
            name: 'stylistForm',
            component: Casting,
            props: true
          },
          {
            path: 'aDirectorForm',
            name: 'aDirectorForm',
            component: Casting,
            props: true
          },
          {
            path: 'voiceForm',
            name: 'voiceForm',
            component: Casting,
            props: true
          },
          {
            path: 'modelForm',
            name: 'modelForm',
            component: Casting,
            props: true
          },
        ]
      },

    ]
  },

  {
    path: "/dop",
    component: Home,
    beforeEnter(to, from, next) {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSfUae1uTlKBDzVlDOpbQiQ2jJgkmrNNR8PinMuBd-t56uc4dg/viewform?usp=pp_url";
    },

  },
  {
    path: "/sketch",
    component: Home,
    beforeEnter(to, from, next) {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSfSVOlC18PVD_AvsYMDA_PMkYojiNn3abULp8FMs3vZNPqF8g/viewform?usp=pp_url";
    },

  },
  {
    path: "/euro",
    component: Home,
    beforeEnter(to, from, next) {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLScdLuhdtr06yXHza4hLnj0Xs3ghZpkGFaXKFPEkjXws5RNoQQ/viewform?usp=pp_url";
    },
  },
  {
    path: "/music",
    component: Home,
    beforeEnter(to, from, next) {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdH7D_q1qcgEh_o2CJ3RdaGBNrCsnwi2Ymy3uvQ9-hhibpfBg/viewform?usp=pp_url";
    },
  },
  {
    path: "/pic",
    component: Home,
    beforeEnter(to, from, next) {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSeX9K62k8DJXw9PFaVVd_1eKroUvemc-bXOXt_LBoaC-7Q5jw/viewform";
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect : '/ar'
  }

]

const scrollBehavior = (to : any, from : any, savedPosition : any) => {
    if (to.name === 'About') {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash = '#about-us',
          });
        }, 1000);

      })
    }
    if (to.name === 'Contact') {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash = '#contact-us',
          });
        }, 1000);

      })
    }

  if (to.hash) {
    return new Promise((resolve, reject) => {
      VueScrollTo.scrollTo(to.hash, 700);

      setTimeout(() => {
        resolve({
          el: to.hash,
        });
      }, 1000);
    });
   } else if (from.name === 'PageNotFound') {
       return savedPosition;
   } else {
       return { top: 0 }
   }
};


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior

})

router.beforeEach(
  async (to, from, next) => {
      // Set locale based on url
      i18n.global.locale = to.params.locale || process.env.VUE_APP_I18N_LOCALE;

      // Redirect to locale url based on from
      if (
          from.params.locale
          && !to.params.locale
      ) {
          try {
              await router.push({ 'path': '/' + from.params.locale + to.fullPath})
          } catch (error: any) {
              if (error.name === 'NavigationDuplicated') {
                  // Do nothing
              } else {
                  throw error;
              }
          }
          return;
      }

      next();
  }
)



export default router
