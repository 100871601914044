import { createI18n } from 'vue-i18n'

import mitt from 'mitt'
const emitter = mitt()

// import EventBus from "@/EventBus"

// const emitter = inject("emitter"); // Inject `emitter`

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

function checkDefaultLanguage () {
  let matched = null
  const languages = Object.getOwnPropertyNames(loadLocaleMessages())
  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang
    }
  })
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0]
      if (lang.split('-')[0] === languagePartials) {
        matched = lang
      }
    })
  }
  return matched
}

// console.log('checkDefaultLanguage', checkDefaultLanguage())
export const selectedLocale =
   process.env.VUE_APP_I18N_LOCALE || 'ar'
export const languages = Object.getOwnPropertyNames(loadLocaleMessages())
// console.log('languages', languages)

export default createI18n({
  legacy: true,
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
  messages: loadLocaleMessages(),
  globalInjection: true
})
