<template>
  <div class="relative px-5 py-0 mx-auto md:py-2 md:px-5 ">
    <!-- bg-dots -->
    <span v-if="location === 'home'"
      class="absolute top-1/4 h-1/2  bg-dots bg-no-repeat bg-cover right-0 md:-right-4 lg:w-40 md:w-30 sm:w-20 opacity-50"></span>
    <span v-if="location === 'home'"
      class="absolute top-1/4 h-1/2  bg-dots bg-no-repeat bg-cover left-0 md:-left-4 lg:w-40 md:w-30 sm:w-20 opacity-50"></span>
    <!-- Works component -->
    <div class="relative flex flex-col justify-center items-center text-center  mx-auto xl:w-2/3 md:w-10/12 ">
      <h1 class=" text-red-500 pb-10 rtl:font-cairo font-oswald font-semibold text-3xl md:text-5xl md:rtl:text-4xl rtl:tracking-normal tracking-widest">{{ $t('section.'+name+'') }}</h1>
<!--Heading-->
    <heading v-if="cat !== '2' && location !== 'home' " :cat='cat' />
<!--End Heading-->
      <div class="relative h-full w-full py-5 overflow-hidden">

        <!-- Start Corners -->
        <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 transform -rotate-45 h-10 w-10
        absolute top-0 right-0 z-0 -mt-5 -mr-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 5l7 7-7 7" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 transform rotate-45 h-10 w-10
          absolute top-0 left-0 z-0 -mt-5 -ml-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 5l7 7-7 7"
            transform="scale (-1, 1)" transform-origin="center" />
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 transform rotate-45 h-10 w-10
        absolute bottom-0 right-0 z-0 -mb-5 -mr-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 5l7 7-7 7" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 transform -rotate-45 h-10 w-10
          absolute bottom-0 left-0 z-0  -mb-5 -ml-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 5l7 7-7 7"
            transform="scale (-1, 1)" transform-origin="center" />
        </svg>
        <!-- End Corners -->

        <div class="flex flex-row flex-wrap h-full w-full justify-center" :class="[paddingColumns]">
          <div v-for="response in works" :key="response.order" :class="[gridColumns] " class="relative flex flex-grow xl:flex-grow-0  w-5/12 py-2 px-2 justify-  items-center justify-center" >
              <!-- Videos Cats -->
              <img v-if="response.excerpt != null"
                   v-lazy="`${imagePath}/${response.image}`" class="w-auto" :alt="$t('section.'+name+'')">

              <!-- Photos Cats -->
              <img
              v-if="response.excerpt === null"
              @click="$store.commit('showLightBox', { thumbImage: response.image, mainImage : response.mainimage, excerpt:response.excerpt, cat: cat, id: response.id })"
              v-lazy="`${imagePath}/${response.image}`" class="w-auto cursor-pointer" :alt="$t('section.'+name+'')">

               <!-- Video Modal "Video not images" -->
              <button  v-if="response.excerpt != null " @click="$store.commit('showModal', response.excerpt)"
                class="absolute box-border inline-flex items-center justify-center mb-6 w-10 h-10 md:w-20 md:h-20 font-sans text-sm leading-none text-white no-underline bg-gray-800 border bg-opacity-30 border-gray-600 border-solid rounded-full cursor-pointer hover:bg-red-700 hover:border-black-700 hover:text-white focus-within:bg-red-700 focus-within:border-black-700 focus-within:text-white sm:text-base md:text-lg">
                <svg class="w-6 h-6 md:w-8 md:h-8 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="">
                  <path d="M0 0h24v24H0z" stroke="none"></path>
                  <path d="M7 4v16l13-8z"></path>
                </svg>
              </button>

          </div>
        </div>

      </div>
      <!-- See More -->
      <router-link v-if="location === 'home'" :to="{name: page}" tag="a" class="text-white rtl:font-cairo font-oswald font-extralight md:text-xl uppercase rtl:tracking-normal tracking-widest">
      {{ $t('general.seeMore') }}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto text-red-500" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
        </svg>
     </router-link>

    </div>
  <light-box></light-box>
  </div>
</template>

<script>
import {
  defineComponent, ref
} from 'vue'
import WorksDataService from '@/services/WorksDataService'
import heading from '@/components/Heading.vue'
import LightBox from '@/components/LightBox.vue'
export default defineComponent({
  name: 'works',
  props: ['name', 'cat', 'page', 'location'],

  setup (props) {
    const works = ref([])
    const currentwork = ref(0)
    const imagePath = ref('https://jumpnew.jump.sa/storage/')
    const workCat = ref(props.cat)
    const gridColumns = 'md:w-4/12'
    return {
      works,
      currentwork,
      imagePath,
      workCat,
      gridColumns
    }
  },
  components: {
    heading,
    LightBox
  },

  data () {
    return {
      currentIndex: -1,
      title: '',
      modalShowing: false,
      paddingColumns : ''
    }
  },
  methods: {
    retrieveWorks () {
      WorksDataService.get(this.workCat)
        .then((response) => {
          const worksD = response.data

          if (this.location === 'home') {
            this.works = worksD.filter(worksD => worksD.featured === 1).sort((a, b) => a.order - b.order)
          } else {
            this.works = worksD.sort((a, b) => a.order - b.order)
          }
        })
        .catch((e) => {
          // console.log(e)
        })

      if (this.cat === '4') {
        this.gridColumns = 'md:w-6/12'
        this.paddingColumns = 'md:pl-20 md:pr-20 xl:pl-36 xl:pr-36'
      } else {
        this.gridColumns = 'md:w-4/12'
      }
    }
  },
  computed: {

  },

  mounted () {
    this.retrieveWorks()
  }

})
</script>

<style scoped lang="scss">
.modal {
  width: 300px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  text-align: center;
}

</style>
