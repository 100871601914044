<template>
 <div>
   <section class="flex flex-col w-full h-full antialiased bg-black  ">
      <Header></Header>
      <slider class="xl:-mt-20 h-full" cat= '6' :sliderCat= "comForm" :location="page"></slider>
    </section>

   <component :is="comForm"></component>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Slider from '@/components/slider.vue'

import ActorForm from '@/components/talent/actorForm.vue'
import DirectorForm from '@/components/talent/directorForm.vue'
import VgrapherForm from '@/components/talent/VgrapherForm.vue'
import Photographer from '@/components/talent/PhotographerForm.vue'
import editorForm from '@/components/talent/editorForm.vue'
import stylistForm from '@/components/talent/stylistForm.vue'
import aDirectorForm from '@/components/talent/aDirectorForm.vue'
import voiceForm from '@/components/talent/voiceForm.vue'
import modelForm from '@/components/talent/modelForm.vue'

import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'Casting',
  props: {
    showMenu: String,
    url: String,
    section: String

  },

  setup () {
    const { t } = useI18n({})
    const title = t('general.home.title')
    const desc = t('general.home.description')
    const keys = t('general.home.keys')

    useMeta({
      title: `${title}`,
      description: `${desc}`,
      meta: [
        {
          name: 'keywords',
          content: `${keys}`
        },
        { property: 'og:title', content: `${title}` },
        { property: 'og:keywords', content: `${keys}` },

        { property: 'og:site_name', content: 'Jump sa' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },

        { property: 'og:url', content: 'https://jump.sa' },
        { property: 'og:description', content: `${desc}` },

        // Twitter card
        { name: 'twitter:card', content: `${desc}` },
        { name: 'twitter:site', content: 'https://jump.sa' },
        { name: 'twitter:title', content: `${title}` },
        { name: 'twitter:description', content: `${desc}` },
        // Your twitter handle, if you have one.
        { name: 'twitter:creator', content: '@Jump sa' },

        // Google / Schema.org markup:
        { itemprop: 'name', content: `${title}` },
        { itemprop: 'description', content: `${desc}` }
      ]

    })
  },

  data () {
    return {
      isHome: false,
      page: 'casting'
    }
  },
  computed: {
    comForm () {
      return this.section
    },
    sliderCat () {
      return this.workCat
    }
  },
  components: {
    ActorForm,
    DirectorForm,
    VgrapherForm,
    Photographer,
    editorForm,
    stylistForm,
    aDirectorForm,
    voiceForm,
    modelForm,
    Header,
    Slider
  }

})
</script>
