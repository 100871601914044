<template>
 <div>
   <section class="flex flex-col w-full h-full antialiased bg-black  ">
      <Header></Header>
      <slider class="xl:-mt-20 h-full " cat= 0 :location="page"></slider>
    </section>
    <!-- Clients -->
    <section class=" bg-white ">
      <clients></clients>
    </section>

    <!-- Works -->
    <div class=" bg-black"
         v-for="work in $store.state.worksCat.sort((a, b) => a.order - b.order)"
         :key="work.order">
      <section v-if="work.cat !== '4'" :id="work.ref" class="py-10">
        <works :name="work.name" :cat=work.cat :page=work.ref :location="page"></works>
      </section>
    </div>

    <!-- About -->
    <section class=" bg-white ">
      <about-us></about-us>
   </section>

    <!-- Contact -->
    <contactUs id="contact-us"></contactUs>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Slider from '@/components/slider.vue'

import Works from '@/components/works.vue'
import ContactUs from '@/components/contactUs.vue'
import AboutUs from '@/components/aboutUs.vue'
import Clients from '@/components/Clients.vue'

import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'Home',
  props: {
    showMenu: String,
    url: String
  },

  setup () {
    const { t } = useI18n({})
    const title = t('general.home.title')
    const desc = t('general.home.description')
    const keys = t('general.home.keys')

    useMeta({
      title: `${title}`,
      description: `${desc}`,
      meta: [
        {
          name: 'keywords',
          content: `${keys}`
        },
        { property: 'og:title', content: `${title}` },
        { property: 'og:keywords', content: `${keys}` },

        { property: 'og:site_name', content: 'Jump sa' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },

        { property: 'og:url', content: 'https://jump.sa' },
        { property: 'og:description', content: `${desc}` },

        // Twitter card
        { name: 'twitter:card', content: `${desc}` },
        { name: 'twitter:site', content: 'https://jump.sa' },
        { name: 'twitter:title', content: `${title}` },
        { name: 'twitter:description', content: `${desc}` },
        // Your twitter handle, if you have one.
        { name: 'twitter:creator', content: '@Jump sa' },

        // Google / Schema.org markup:
        { itemprop: 'name', content: `${title}` },
        { itemprop: 'description', content: `${desc}` }
      ]

    })
  },

  data () {
    return {
      isHome: true,
      page: 'home'
    }
  },

  components: {
    Clients,
    Works,
    ContactUs,
    AboutUs,
    Header,
    Slider
  }
})
</script>
