<template>
<div>
   <section class="flex flex-col w-full h-full antialiased bg-black  ">
      <Header></Header>
     <slider class="xl:-mt-20" cat='100'></slider>

<!--     Heading -->
     <div class="relative flex flex-col justify-center items-center  mx-auto xl:w-10/12 md:w-10/12 ">

       <div  class="relative flex flex-grow xl:flex-grow-0  w-5/12 py-2 px-2 justify-  items-center justify-center" >
         <!-- Videos Cats -->
         <img :src="`${ $t('actors.video.img') }`" class="w-auto md:w-2/3" :alt="$t('actors.title')">

         <!-- Video Modal " -->
         <button  @click="$store.commit('showModal', 'https://youtube.com/shorts/6FzPlGjPVxM')"
                  class="absolute box-border inline-flex items-center justify-center mb-6 w-10 h-10 md:w-20 md:h-20 font-sans text-sm leading-none text-white no-underline bg-gray-800 border bg-opacity-30 border-gray-600 border-solid rounded-full cursor-pointer hover:bg-red-700 hover:border-black-700 hover:text-white focus-within:bg-red-700 focus-within:border-black-700 focus-within:text-white sm:text-base md:text-lg">
           <svg class="w-6 h-6 md:w-8 md:h-8 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="">
             <path d="M0 0h24v24H0z" stroke="none"></path>
             <path d="M7 4v16l13-8z"></path>
           </svg>
         </button>

       </div>

     <div class="relative flex flex-col justify-center rtl:font-cairo font-oswald items-center text-center rtl:text-center text-gray-300 pt-5 pb-10 space-y-2 ">
       <h2 class="bold text-2xl font-bold text-red-600 py-5">{{ $t('actors.subtitle') }} </h2>
       <p class="text-md rtl:text-md leading-7 px-5 md:text-xl md:rtl:text-lg font-light "
            v-html="$t('actors.desc',{ whatsapp : `<a target='_blank' href='https://api.whatsapp.com/send?phone=+966540233695&text=انا مهتم لمعرفة المزيد عن الممثلين'> <img class='w-14 inline' src=${ $t('actors.whatsapp_icon') } /></a>`})">
       </p>

     </div>
     </div>
   </section>

  <div class="flex flex-row flex-wrap h-full w-full justify-center md:pl-20 md:pr-20 xl:pl-36 xl:pr-36">
    <div :key="key" v-for="(img, key) in images"  class="relative flex flex-grow xl:flex-grow-0  py-2 px-2 w-4/12 md:w-3/12 lg:w-2/12  items-center justify-center" >
      <img  :src="imageDir + key.substr(1)" class="w-auto">
    </div>
  </div>
  <contact-us></contact-us>
</div>

</template>

<script>
import Header from '@/components/header.vue'
import Slider from '@/components/slider.vue'

import { useStore } from 'vuex'
import ContactUs from '@/components/contactUs.vue'

import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'Actors',
  props: {
    pageTitle: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { t } = useI18n({})
    const title = t('general.video.title')
    const desc = t('general.video.description')
    const keys = t('general.video.keys')
    // //  set video
    // const store = useStore()
    // store.commit('setSliderVideo', 'video-production-conv.mp4') // 'video-production.mp4')

    useMeta({
      title: `${title}`,
      description: `${desc}`,
      meta: [
        {
          name: 'keywords',
          content: `${keys}`
        },
        { property: 'og:title', content: `${title}` },
        { property: 'og:keywords', content: `${keys}` },

        { property: 'og:site_name', content: 'Jump sa' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },

        { property: 'og:url', content: 'https://jump.sa' },
        { property: 'og:description', content: `${desc}` },

        // Twitter card
        { name: 'twitter:card', content: `${desc}` },
        { name: 'twitter:site', content: 'https://jump.sa' },
        { name: 'twitter:title', content: `${title}` },
        { name: 'twitter:description', content: `${desc}` },
        // Your twitter handle, if you have one.
        { name: 'twitter:creator', content: '@Jump sa' },

        // Google / Schema.org markup:
        { itemprop: 'name', content: `${title}` },
        { itemprop: 'description', content: `${desc}` }
      ]

    })
  },
  components: {
    ContactUs,
    Header,
    Slider
  },

  data () {
    return {
      imageDir: '/actors/',
      images: {}
    }
  },
  mounted () {
    this.importAll(require.context('../../public/actors/', true, /\.jpg$/))
  },
  methods: {
    importAll (r) {
      const imgs = {}
      r.keys().forEach(key => (imgs[key] = r(key)))
      this.images = imgs
    }
  }

})
</script>
