<template>
  <Modal :model-value="$store.state.isShow"  @update:modelValue="$store.commit('closeModal')"
    wrapper-class="modal-wrapper"
    @before-open="beforeOpen" @before-close="beforeClose">
    <YouTube class="videojump" :src="$store.state.videoId" @ready="onReady"
              :vars="videoVars" :width="youtubeWidth" :height="youtubeHeight" ref="youtubeJump" />
  </Modal>
</template>

<script>
import { defineComponent } from 'vue'
import YouTube from 'vue3-youtube'
import VueModal from '@kouts/vue-modal'
import bodyScroll from 'body-scroll-freezer'

export default defineComponent({
  name: 'vmodal',
  components: {
    YouTube,
    Modal: VueModal

  },
  setup () {

  },
  data () {
    return {
      videoVars: {
        autoplay: 1,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        start: 0
      },
      window: {
        width: 0,
        height: 0
      },
      youtubeWidth: 640,
      youtubeHeight: 360
    }
  },
  methods: {
    onReady () {
      this.$refs.youtubeJump.playVideo()
    },
    beforeOpen () {
      bodyScroll.freeze()
      // console.log('$store.state.videoId')
    },
    beforeClose () {
      bodyScroll.unfreeze()
    },
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight

      if (this.window.width > 720) {
        this.youtubeWidth = 640
        this.youtubeHeight = 420
      }

      if (this.window.width < 720 && this.window.width > 480) {
        this.youtubeWidth = screen.width - 50
        this.youtubeHeight = 315
      }
      if (this.window.width < 480) {
        this.youtubeWidth = screen.width - 50
        this.youtubeHeight = 260
      }
    },
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  // unmounted () {
  //   window.removeEventListener('resize', this.handleResize)
  // },

  computed: {

  },
  mounted () {
    bodyScroll.init()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }
})
</script>

<style>
  .modal-wrapper {
    display: flex;
    align-items: center;
  }

  .modal-wrapper .vm {
    top: auto;
  }
</style>
